var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-1",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"flex":"0 150px"}},[_vm._v(_vm._s(_vm.group))]),_c('div',{class:_vm.itemClass,staticStyle:{"flex":"1"}},[_c('span',{style:(_vm.listeningToItemSelect ? { cursor: 'pointer' } : {}),on:{"click":_vm.selectItem}},[_vm._v(_vm._s(_vm.item))]),_vm._l((_vm.itemTags),function(t){return _c('span',{key:t,staticClass:"grey lighten-2 text-caption",staticStyle:{"border-radius":"10px","padding":"0 8px 0","margin-left":"10px"}},[_vm._v(_vm._s(t))])})],2),_c('div',{staticClass:"pr-1",staticStyle:{"flex":"0 100px","text-align":"right"}},[_vm._v(_vm._s(_vm.amountDigested))]),_c('div',{staticStyle:{"flex":"0 100px","text-align":"right"}},[(_vm.priceRaw)?_c('NumberChangeIndicator',{attrs:{"number":_vm.priceRaw,"label":_vm.price},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var display = ref.display;
return [_vm._v(_vm._s(display))]}}],null,false,206777265)}):_vm._e()],1),_c('div',{class:_vm.totalClass,staticStyle:{"flex":"0 120px","text-align":"right","font-weight":"bold"}},[_c('NumberChangeIndicator',{attrs:{"number":_vm.priceRaw,"label":_vm.total},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var display = ref.display;
return [_vm._v(_vm._s(display))]}}])})],1),_c('div',{staticClass:"grey--text",staticStyle:{"flex":"0 70px","text-align":"right"}},[_vm._v(_vm._s(_vm.percentage))]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.firstTagTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"green--text",staticStyle:{"flex":"0 100px","text-align":"right"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.firstTag)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.firstTagTooltip))])]),_c('v-tooltip',{attrs:{"right":"","disabled":!_vm.secondTagTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"green--text",staticStyle:{"flex":"0 100px","text-align":"right","font-weight":"bold"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.secondTag)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.secondTagTooltip))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }