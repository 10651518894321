











































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import ExpenseForm from "@/components/cashbook/ExpenseForm.vue";
import IncomeForm from "@/components/cashbook/IncomeForm.vue";
import GeneralSettingsRow from "@/components/cashbook/GeneralSettingsRow.vue";
import SummaryRow from "@/components/cashbook/SummaryRow.vue";
import {CashBook, ExpenseRow, IncomeRow} from "@/model/cashbook";
import moment from "moment";
import {dateFormat, numberFormat, showSnackbar} from "@/utils";
import {getCashBookPdf} from "@/api";
import PageHeader from "@/components/PageHeader.vue";
@Component({
  components: {PageHeader, SummaryRow, GeneralSettingsRow, IncomeForm, ExpenseForm}
})
export default class CashBookView extends Vue {

  startDate = '';
  endDate = '';
  startValueRaw = '';
  rows: Array<IncomeRow | ExpenseRow> = [];
  dialogDatePicker = false;
  dialogEdit = false;
  dialogInsert = false;
  selected: IncomeRow | ExpenseRow = { date: '', description: '', type: 'INCOME', income: 0 };
  insertIndex = 0;
  dateNew = '';
  dateUpdating = '';
  editingStartDate = false;
  editingEndDate = false;

  openDatePicker() {
    this.editingStartDate = false;
    this.editingEndDate = false;
    this.dialogDatePicker = true;
  }

  openEdit(item: IncomeRow | ExpenseRow) {
    this.selected = item;
    this.dateUpdating = item.date;
    this.dialogEdit = true;
  }

  openInsert(index: number) {
    this.insertIndex = index;
    this.dialogInsert = true;
  }

  openEditStartDate() {
    this.editingStartDate = true;
    this.editingEndDate = false;
    this.dialogDatePicker = true;
  }

  openEditEndDate() {
    this.editingStartDate = false;
    this.editingEndDate = true;
    this.dialogDatePicker = true;
  }

  addRow(data: IncomeRow | ExpenseRow) {
    this.rows.push(data);
  }

  insertRow(data: IncomeRow | ExpenseRow) {
    this.rows.splice(this.insertIndex + 1, 0, data);
    this.dialogInsert = false;
  }

  updateRow(data: IncomeRow | ExpenseRow) {
    if (this.selected.type === 'INCOME') {
      this.selected.date = this.dateUpdating;
      this.selected.description = data.description;
      (this.selected as IncomeRow).income = (data as IncomeRow).income;
    } else {
      this.selected.date = this.dateUpdating;
      this.selected.description = data.description;
      (this.selected as ExpenseRow).expenseId = (data as ExpenseRow).expenseId;
      (this.selected as ExpenseRow).expense = (data as ExpenseRow).expense;
    }
    this.dialogEdit = false;
  }

  deleteRow(index: number) {
    this.rows.splice(index, 1);
  }

  loadJson(data: CashBook) {
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.startValueRaw = this.currencyFormat(data.startValue);
    this.rows = data.rows;
  }

  async getPdf() {
    try {

      const win = window.open('about:blank', '_blank');
      const { token } = await getCashBookPdf({
        startDate: this.startDate,
        endDate: this.endDate,
        startValue: this.startValue,
        rows: this.rows
      });

      win!.location.href = '/api/cashbook?token='+token;
    } catch (e) {
      showSnackbar('Ein Fehler ist aufgetreten.');
    }
  }

  get currencyFormat() {
    return (number: number) => numberFormat(number, 2);
  }

  get dateFormat() {
    return (date: string) => dateFormat(date);
  }

  get sumIncome() {
    let sum = 0;
    for (const item of this.rows) {
      const income = (item as IncomeRow).income;
      if (income) {
        sum += income;
      }
    }

    return sum;
  }

  get sumExpense() {
    let sum = 0;
    for (const item of this.rows) {
      const expense = (item as ExpenseRow).expense;
      if (expense) {
        sum += expense;
      }
    }

    return sum;
  }

  get startValue() {
    const startValue = parseFloat(this.startValueRaw
        .replaceAll('.', '')
        .replaceAll(',', '.')
    );
    if(isNaN(startValue)) {
      return 0;
    }

    return startValue;
  }

  get endValue() {
    return this.startValue + this.sumIncome - this.sumExpense;
  }

  get jsonData() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      startValue: this.startValue,
      rows: this.rows
    } as CashBook;
  }

  get jsonName() {
    return 'Kassenabrechnung ' + moment(this.startDate).format('YYYY-MM') + '.json';
  }

  @Watch('startDate')
  watchStartDate(newVal: string, oldVal: string) {
    const prevDate = moment(oldVal);
    const nextDate = moment(newVal);

    if (prevDate.month() !== nextDate.month()) {
      this.endDate = nextDate.endOf('month').format('YYYY-MM-DD');
    }
  }

  mounted() {
    const today = new Date();
    this.startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1)).toISOString().substr(0, 10);
    this.endDate = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0)).toISOString().substr(0, 10);
    this.dateNew = this.startDate;
    this.dateUpdating = this.startDate;
  }
}
