
import {Component, Mixins, Prop} from 'vue-property-decorator';
import { Pie, mixins } from 'vue-chartjs';
import { ChartData, ChartOptions } from "chart.js";

@Component({
  extends: Pie,
  mixins: [mixins.reactiveProp]
})
export default class CommitChart extends Mixins(mixins.reactiveProp, Pie) {

  @Prop({required: true})
  readonly chartData!: ChartData;

  @Prop({required: true})
  readonly options!: ChartOptions;

  mounted () {
    this.renderChart(this.chartData, this.options);
  }
}

