var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grey lighten-3",staticStyle:{"min-height":"100vh"}},[_c('div',{staticStyle:{"max-width":"1400px","margin":"auto","padding":"20px 20px 100px 20px"}},[_c('PageHeader',{attrs:{"json-name":"crypto-history.json","json-data":_vm.jsonData},on:{"load":_vm.loadJson},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"display":"flex"}},[_c('div',[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("Investiert")]),_c('AnimatedNumber',{attrs:{"number":_vm.totalInvested,"animation-duration":1500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var curr = ref.curr;
return [_c('div',{staticClass:"text-h3 mt-1"},[_vm._v(_vm._s(_vm.currencyFormat(curr)))])]}}])})],1),_c('div',{staticClass:"ml-12"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("Aktuell")]),_c('AnimatedNumber',{attrs:{"number":_vm.totalCurrent,"animation-duration":1500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var curr = ref.curr;
return [_c('div',{staticClass:"text-h3 mt-1"},[_vm._v(_vm._s(_vm.currencyFormat(curr)))])]}}])})],1),_c('div',{staticClass:"ml-12"},[_c('div',{staticClass:"text-subtitle-1"},[_vm._v("Rendite")]),_c('div',[_c('AnimatedNumber',{staticStyle:{"display":"inline-block"},attrs:{"number":_vm.totalCurrent - _vm.totalInvested,"animation-duration":1500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var curr = ref.curr;
return [_c('span',{staticClass:"text-h3 mt-1",class:_vm.totalCurrent > _vm.totalInvested ? ['green--text'] : ['red--text']},[_vm._v(" "+_vm._s(_vm.totalCurrent > _vm.totalInvested ? '+' : '')+_vm._s(_vm.currencyFormat(curr))+" ")])]}}])}),_c('AnimatedNumber',{staticClass:"ml-6",staticStyle:{"display":"inline-block"},attrs:{"number":_vm.returnRaw(_vm.totalInvested, _vm.totalCurrent),"animation-duration":1500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var curr = ref.curr;
return [_c('span',{staticClass:"text-h5 mt-1",class:_vm.totalCurrent > _vm.totalInvested ? ['green--text'] : ['red--text']},[_vm._v(" ("+_vm._s(_vm.totalCurrent > _vm.totalInvested ? '+' : '')+_vm._s(_vm.percentFormat(curr * 100))+") ")])]}}])})],1)])])]},proxy:true}])}),_c('PortfolioSubHeader',{staticClass:"mt-12",attrs:{"title":"Kryptowährungen","icon":"mdi-chart-line","color-class":"black--text"}}),_c('div',{staticClass:"mt-4",staticStyle:{"display":"flex"}},_vm._l((_vm.prices),function(price,coin,index){return _c('v-sheet',{key:index,staticClass:"pa-3",class:{'ml-3': index, 'mr-3': index !== _vm.prices.length-1},staticStyle:{"flex":"1","color":"rgba(0, 0, 0, 0.6)","white-space":"nowrap"},attrs:{"elevation":"2","rounded":""}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(coin))]),_c('NumberChangeIndicator',{attrs:{"number":price},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var display = ref.display;
return [_c('p',{staticClass:"text-h5 ma-0"},[_vm._v(_vm._s(_vm.currencyFormat(display)))])]}}],null,true)}),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.amountFormat(_vm.totalAmountCoin[coin], 3))+" x")]),_c('p',{staticClass:"mt-3 mb-0 font-weight-bold"},[_vm._v("Performance:")]),_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.currencyFormat(_vm.totalInvestedCoin[coin]))+" investiert.")]),_c('NumberChangeIndicator',{attrs:{"number":_vm.totalCurrentCoin[coin]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var display = ref.display;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(display))+" ")]}}],null,true)}),_vm._v(" aktuell. ")],1)}),1),_c('PortfolioSubHeader',{attrs:{"title":"Historie","icon":"mdi-clock-outline","color-class":"black--text"}}),_c('v-card',{staticClass:"mt-4"},[_c('v-card-text',_vm._l((_vm.history),function(h,index){return _c('div',{key:index,staticClass:"mb-1",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"flex":"0 150px"}},[_vm._v(_vm._s(h.date))]),_c('div',{staticStyle:{"flex":"1","cursor":"pointer"},on:{"click":function($event){return _vm.showPriceHistory(h.coin, h.coin)}}},[_vm._v(_vm._s(h.coin))]),_c('div',{staticStyle:{"flex":"0 150px"}},[_vm._v(_vm._s(_vm.amountFormat(h.amount, 4)))]),_c('div',{staticStyle:{"flex":"0 150px"}},[_vm._v(_vm._s(_vm.currencyFormat(h.price)))]),_c('div',{staticStyle:{"flex":"0 150px"}},[_vm._v(_vm._s(_vm.currencyFormat(h.total)))]),_c('div',{staticStyle:{"flex":"0 150px"}},[_c('NumberChangeIndicator',{attrs:{"number":h.amount * _vm.prices[h.coin]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var display = ref.display;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(display))+" ")]}}],null,true)})],1),_c('div',{class:h.amount * _vm.prices[h.coin] > h.total ? ['green--text'] : ['red--text'],staticStyle:{"flex":"0 100px"}},[_vm._v(_vm._s(_vm.returnFormat(h.total, h.amount * _vm.prices[h.coin])))]),_c('div',{staticClass:"pr-1",staticStyle:{"flex":"0 100px","text-align":"right"}},[_vm._v(_vm._s(h.type))]),_c('div',{staticClass:"pr-1",staticStyle:{"flex":"0 100px","text-align":"right"}},[_vm._v(_vm._s(_vm.sellDate(h.date)))])])}),0)],1)],1),(_vm.selectedItemForPriceHistory)?_c('DetailPopup',{attrs:{"title":_vm.selectedItemForPriceHistory.title,"symbol":_vm.selectedItemForPriceHistory.symbol},on:{"close":function($event){_vm.selectedItemForPriceHistory = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }