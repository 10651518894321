


























































































import {Component, Vue} from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import {numberFormat} from "@/utils";
import {getPrices} from "@/api";
import AnimatedNumber from "@/components/portfolio/AnimatedNumber.vue";
import DetailPopup from "@/components/portfolio/DetailPopup.vue";
import NumberChangeIndicator from "@/components/portfolio/NumberChangeIndicator.vue";
import PortfolioSubHeader from "@/components/portfolio/PortfolioSubHeader.vue";
import {CryptoHistory, CryptoHistoryItem} from "@/model/crypto-history";
import moment from "moment";

type PriceHistoryItem = {
  title: string;
  symbol: string;
}

@Component({
  components: {PortfolioSubHeader, NumberChangeIndicator, DetailPopup, AnimatedNumber, PageHeader}
})
export default class CryptoHistoryView extends Vue {

  prices: Record<string, number> = {};
  history: CryptoHistoryItem[] = [];

  totalAmountCoin: Record<string, number> = {};
  totalInvestedCoin: Record<string, number> = {};
  totalCurrentCoin: Record<string, number> = {};
  totalInvested = 0;
  totalCurrent = 0;

  selectedItemForPriceHistory: PriceHistoryItem | null = null;

  timerId: number | null = null;

  get amountFormat() {
    return (number: number, decimals: number) => numberFormat(number, decimals);
  }

  get currencyFormat() {
    return (number: number) => numberFormat(number, 2, '€');
  }

  get percentFormat() {
    return (number: number, decimals = 1) => numberFormat(number, decimals, '%');
  }

  get returnRaw() {
    return (buy: number, sell: number) => sell / buy - 1;
  }

  get returnFormat() {
    return (buy: number, sell: number) => {
      let result = this.percentFormat(this.returnRaw(buy, sell) * 100);
      if (sell > buy)
        result = '+' + result;
      return result;
    }
  }

  get sellDate() {
    return (buyDate: string) => moment(buyDate).add(1, 'y').format('DD.MM.YYYY')
  }

  get jsonData() {
    return {
      prices: this.prices,
      history: this.history
    } as CryptoHistory;
  }

  loadJson(data: CryptoHistory) {
    this.prices = data.prices;
    this.history = data.history;
    this.calculate();
  }

  showPriceHistory(title: string, symbol: string) {
    this.selectedItemForPriceHistory = {title, symbol};
  }

  calculate() {

    // reset
    this.totalAmountCoin = {};
    this.totalInvestedCoin = {};
    this.totalCurrentCoin = {};
    this.totalInvested = 0;
    this.totalCurrent = 0;

    // init
    const symbols = [...new Set(this.history.map(h => h.coin))];
    for (const symbol of symbols) {
      this.totalAmountCoin[symbol] = 0;
      this.totalInvestedCoin[symbol] = 0;
      this.totalCurrentCoin[symbol] = 0;
    }

    for (const h of this.history) {
      const now = h.amount * this.prices[h.coin]
      this.totalAmountCoin[h.coin] += h.amount;
      this.totalInvestedCoin[h.coin] += h.total;
      this.totalCurrentCoin[h.coin] += now;
      this.totalInvested += h.total;
      this.totalCurrent += now;
    }
  }

  async tick() {
    const symbols = [...new Set(this.history.map(h => h.coin))];
    const result = await getPrices(symbols); // fetch
    let changed = false;

    // update coin prices
    for (const symbol of symbols) {
      const price = result.find(r => r.symbol === symbol);
      if (price) {
        this.prices[symbol] = price.price;
        changed = true;
      }
    }

    if (changed) {
      this.calculate();
    }
  }

  mounted() {
    this.timerId = setInterval(this.tick, 1500);
  }
}
