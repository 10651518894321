







import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class SummaryRow extends Vue {

  @Prop({required: true})
  readonly keyText!: string;
}
