






































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import FormRow from "@/components/cashbook/FormRow.vue";
import {dateFormat} from "@/utils";
@Component({
  components: {FormRow}
})
export default class IncomeForm extends Vue {

  @Prop({required: true})
  readonly date!: string;

  @Prop({required: false, default: false})
  readonly editMode!: boolean;

  @Prop({required: false})
  readonly prevDescription!: string | null;

  @Prop({required: false})
  readonly prevIncome!: string | null;

  description = 'Umsatz';
  income = '';

  openDatePicker() {
    this.$emit('open-date-picker');
  }

  submit() {
    const incomeValue = parseFloat(this.income.replaceAll(',', '.'));
    if(isNaN(incomeValue)) {
      return;
    }

    this.$emit('submit', {
      date: this.date,
      description: this.description,
      income: incomeValue,
      type: 'INCOME'
    });
  }

  get dateFormat() {
    return (date: string) => dateFormat(date);
  }

  @Watch('prevDescription', { immediate: true })
  watchDescription(newVal: string) {
    if (newVal)
      this.description = newVal;
  }

  @Watch('prevIncome', { immediate: true })
  watchIncome(newVal: number) {
    if (newVal || newVal === 0)
      this.income = `${newVal}`;
  }
}
