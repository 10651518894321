






















import {Component, Vue} from "vue-property-decorator";
import {initSnackbar} from "@/utils";

@Component
export default class App extends Vue {

  snackbar = false;
  snackbarText: string | null = null;

  showSnackbar(text: string) {
    this.snackbarText = text;
    this.snackbar = true;
  }

  mounted() {
    initSnackbar(this.showSnackbar);
  }
}
