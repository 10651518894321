




























import {Component, Prop, Vue} from "vue-property-decorator";
import {showSnackbar} from "@/utils";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component
export default class PageHeader extends Vue {

  @Prop({required: true})
  readonly jsonData!: object;

  @Prop({required: true})
  readonly jsonName!: string;

  get jsonDataParsed() {
    return 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.jsonData, null, 2));
  }

  loadJson(data: HTMLInputEvent) {
    const reader = new FileReader()
    reader.onload = event => {
      try {
        this.$emit('load', JSON.parse(event.target!.result as string));
      } catch (e) {
        showSnackbar('Ein Fehler ist aufgetreten.');
      }
    }
    reader.onerror = () => showSnackbar('Ein Fehler ist aufgetreten.');
    reader.readAsText(data!.target!.files![0]!);
  }
}
