






import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class AnimatedNumber extends Vue {

  @Prop({required: true})
  readonly number!: number;

  @Prop({required: true})
  readonly animationDuration!: number; // in millis

  @Prop({required: false, default: false})
  readonly round!: boolean;

  initialized = false;
  actualNumber = 0;
  animation = {
    from: 0,
    delta: 0,
    startTime: 0
  };
  timerId: number | null = null;

  tick() {
    const percentage = (new Date().getTime() - this.animation.startTime) / this.animationDuration;

    if (percentage >= 1) {
      this.actualNumber = this.animation.from + this.animation.delta;
    } else {
      const actualNumber = this.animation.from + this.animation.delta * percentage;
      this.actualNumber = this.round ? Math.round(actualNumber) : actualNumber;
    }
  }

  @Watch('number', { immediate: true })
  handler(newVal: number, oldVal: number) {

    if (!this.initialized && !oldVal) {
      // set first value immediately
      this.actualNumber = newVal;
      return;
    }

    if (this.timerId) {
      // stop old animation
      clearInterval(this.timerId);
    }

    this.animation = {
      from: oldVal,
      delta: newVal - oldVal,
      startTime: new Date().getTime()
    };

    this.initialized = true;
    this.timerId = setInterval(this.tick, 20);
  }
}
