



































import {Component, Prop, Vue} from "vue-property-decorator";
import NumberChangeIndicator from "@/components/portfolio/NumberChangeIndicator.vue";

@Component({
  components: {NumberChangeIndicator}
})
export default class AssetRow extends Vue {

  @Prop({required: false, default: ''})
  readonly group!: string;

  @Prop({required: true})
  readonly item!: string;

  @Prop({required: false, default: ''})
  readonly itemClass!: string;

  @Prop({required: false, default: () => []})
  readonly itemTags!: string[];

  @Prop({required: false, default: ''})
  readonly amount!: string;

  @Prop({required: false, default: 0})
  readonly priceRaw!: number;

  @Prop({required: false, default: ''})
  readonly price!: string;

  @Prop({required: true})
  readonly total!: string;

  @Prop({required: false, default: ''})
  readonly totalClass!: string;

  @Prop({required: true})
  readonly percentage!: string;

  @Prop({required: false, default: null})
  readonly firstTag!: string | null;

  @Prop({required: false, default: null})
  readonly firstTagTooltip!: string | null;

  @Prop({required: false, default: null})
  readonly secondTag!: string | null;

  @Prop({required: false, default: null})
  readonly secondTagTooltip!: string | null;

  get amountDigested() {
    if (this.amount)
      return `${this.amount} x`;
    else
      return '';
  }

  get listeningToItemSelect() {
    return this.$listeners.select;
  }

  selectItem() {
    if (this.listeningToItemSelect) {
      this.$emit('select');
    }
  }
}
