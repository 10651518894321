







import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class PortfolioSubHeader extends Vue {

  @Prop({required: true})
  readonly title!: string;

  @Prop({required: true})
  readonly icon!: string;

  @Prop({required: true})
  readonly colorClass!: string;
}
