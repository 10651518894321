






import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class NumberChangeIndicator extends Vue {

  @Prop({required: true})
  readonly number!: number;

  @Prop({required: false})
  readonly label!: number | null;

  display = this.label || this.number;
  color: string | null = null;
  colorTimer: number | null = null;
  numberTimer: number | null = null;

  @Watch('number')
  handler(newVal: number, oldVal: number) {
    if (newVal === oldVal || !newVal)
      return;

    this.color = newVal > oldVal ? '#4CAF50' : '#F44336';

    if (this.colorTimer) {
      // stop old animation
      clearInterval(this.colorTimer);
    }
    this.colorTimer = setInterval(() => {
      this.color = null;
    }, 1000);

    if (this.numberTimer) {
      clearInterval(this.numberTimer);
    }
    this.numberTimer = setInterval(() => {
      this.display = this.label || newVal;
    }, 500);
  }
}
