























































import {Component, Prop, Vue} from "vue-property-decorator";
import LineChart from "@/components/chart/LineChart.vue";
import {ChartData, ChartOptions, ChartTooltipItem} from "chart.js";
import {DateWithDividend, getDetail, Logo} from "@/api";
import moment from "moment";
import {numberFormat} from "@/utils";

@Component({
  components: {LineChart}
})
export default class DetailPopup extends Vue {

  @Prop({required: true})
  readonly title!: string;

  @Prop({required: true})
  readonly symbol!: string;

  fetching = true;
  dividends: DateWithDividend[] = [];
  logo: Logo | null = null;
  chartData: ChartData | null = null;

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
    scales: {
      yAxes: [{
        ticks: {
          callback: function(value) {
            return value + ' €';
          }
        }
      }],
      xAxes: [{
        type: 'time',
        time: {
          unit: 'year',
          displayFormats: {
            month: 'MM / YYYY'
          }
        },
        ticks: {
          autoSkip: true,
          autoSkipPadding: 50,
          maxRotation: 0,
          minRotation: 0
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: (item: ChartTooltipItem) => `${item.yLabel} €`,
      },
    },
  };

  async fetchData() {
    try {
      this.fetching = true;
      const response = await getDetail(this.symbol);
      this.dividends = response.dividendHistory;
      this.logo = response.logo;
      this.chartData = {
        labels: response.priceHistory.map(d => moment(d.date)),
        datasets: [{
          data: response.priceHistory.map(d => d.price),
          pointRadius: 0,
          borderWidth: 2,
          borderColor: '#90A4AE',
          fill: false,
          lineTension: 0,
        }]
      };
      this.fetching = false;
    } catch (e) {
      console.log(e);
    }
  }

  get dateFormat() {
    return (date: string) => moment(date).format('DD.MM.YYYY')
  }

  get currencyFormat() {
    return (number: number) => numberFormat(number, 2, '€');
  }

  mounted() {
    this.fetchData();
  }
}
