











import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class RecommendedDownloadCard extends Vue {

  @Prop({required: true})
  readonly title!: string;

  @Prop({required: true})
  readonly subTitle!: string;

  @Prop({required: true})
  readonly icon!: string;
}
