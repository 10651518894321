import moment from "moment";

let snackbarFunc: Function;

export function initSnackbar(func: Function) {
    snackbarFunc = func;
}

export function showSnackbar(text: string) {
    snackbarFunc(text);
}

export function numberFormat(number: number, decimals = -1, suffix = ''): string {
    if ((number || number === 0) && !isNaN(number)) {
        if (suffix)
            suffix = ` ${suffix}`; // add space

        const result = decimals !== -1 ? number.toLocaleString('de', {minimumFractionDigits: decimals, maximumFractionDigits: decimals}) : number.toLocaleString('de');
        return result + suffix;
    } else {
        return '';
    }
}

export function dateFormat(date: string) {
    return moment(date).format('DD.MM.YYYY');
}