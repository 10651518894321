












import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class FormRow extends Vue {

  @Prop({required: true})
  readonly keyText!: string;

  @Prop({required: true})
  readonly icon!: string;
}
