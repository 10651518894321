import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import CashBookView from "@/views/CashBookView.vue";
import PortfolioView from "@/views/PortfolioView.vue";
import CryptoHistoryView from "@/views/CryptoHistoryView.vue";
import CashflowView from "@/views/CashflowView.vue";
import YoutubeView from "@/views/YoutubeView.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/cashbook',
    name: 'CashBook',
    meta: { title: 'Cashbook' },
    component: CashBookView
  },
  {
    path: '/cashflow',
    name: 'Cashflow',
    meta: { title: 'Cashflow' },
    component: CashflowView
  },
  {
    path: '/crypto-history',
    name: 'CryptoHistory',
    meta: { title: 'Crypto History' },
    component: CryptoHistoryView
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    meta: { title: 'Portfolio' },
    component: PortfolioView
  },
  {
    path: '/youtube',
    name: 'Youtube',
    meta: { title: 'YouTube Downloader' },
    component: YoutubeView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || 'tienisto.com';
  });
});

export default router
