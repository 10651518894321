
import {Component, Mixins, Prop} from 'vue-property-decorator';
import { Line, mixins } from 'vue-chartjs';
import { ChartData, ChartOptions } from "chart.js";

@Component({
  extends: Line, // this is important to add the functionality to your component
  mixins: [mixins.reactiveProp]
})
export default class CommitChart extends Mixins(mixins.reactiveProp, Line) {

  @Prop({required: true})
  readonly chartData!: ChartData;

  @Prop({required: true})
  readonly options!: ChartOptions;

  mounted () {
    this.renderChart(this.chartData, this.options);
  }
}

