






















































































































import {Component, Vue} from "vue-property-decorator";
import {
  DownloadFormat,
  downloadOnServer,
  generateToken,
  getDownloadProgress,
  getVideoMetaData,
  VideoMetaData
} from "@/api";
import {showSnackbar} from "@/utils";
import RecommendedDownloadCard from "@/components/youtube/RecommendedDownloadCard.vue";

enum FormatType {
  full,
  audioOnly,
  videoOnly,
}

@Component({
  components: {RecommendedDownloadCard}
})
export default class YoutubeView extends Vue {

  url = '';
  metaData: VideoMetaData | null = null;
  loading = false;
  advanced = false;
  downloading = false;
  downloadingFileName: string | null = null;
  progress = 0;
  progressTimer: number | null = null;

  FormatType = FormatType; // so we can access enum in template

  back() {
    this.url = '';
    this.metaData = null;
  }

  async getFormats() {
    try {
      if (!this.url)
        return;
      this.loading = true;
      this.metaData = await getVideoMetaData(this.url);
    } catch (e) {
      showSnackbar('Ein Fehler ist aufgetreten.');
    } finally {
      this.loading = false;
    }
  }

  async download(code: string, ext: string | null, mp3 = false, mp4 = false) {
    if (this.downloading)
      return;
    try {
      if (!this.url)
        return;
      this.downloadingFileName = `${this.metaData?.title}.${ext}`;
      this.downloading = true;
      this.progress = 0;
      const token = (await generateToken(this.url, code, mp3, mp4)).token;
      this.progressTimer = setInterval(async () => {
        this.progress = (await getDownloadProgress(token)).progress;
      }, 1000);
      await downloadOnServer(token);
      location.href = '/api/youtube/download?token='+token;
    } catch (e) {
      showSnackbar('Ein Fehler ist aufgetreten.');
    } finally {
      this.downloading = false;
      this.downloadingFileName = null;
      if (this.progressTimer) {
        clearInterval(this.progressTimer);
      }
    }
  }

  get formatType() {
    return (format: DownloadFormat) => {
      if (format.videoCodec && format.audioCodec)
        return FormatType.full;
      if (format.videoCodec)
        return FormatType.videoOnly;
      return FormatType.audioOnly;
    }
  }

  get storageString() {
    return (size: number) => {
      if (!size)
        return null;
      if (size < 1024) return size + " B";
      else if (size < 1024 * 1024) return Math.round(size / 1024) + " KB";
      else if (size < 1024 * 1024 * 1024) return Math.round(size / (1024 * 1024)) + " MB";
      else return Math.round(size / (1024 * 1024 * 1024)) + " GB";
    }
  }

  get randomString() {
    return Math.random().toString(36).substring(7);
  }
}
