








import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class AssetCard extends Vue {

  @Prop({required: true})
  readonly title!: string;

  @Prop({required: true})
  readonly percentage!: string;

  @Prop({required: true})
  readonly percentageRaw!: number;

  @Prop({required: true})
  readonly absolute!: string;
}
