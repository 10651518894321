import {CashBook} from "@/model/cashbook";

const API_URL = "/api";

interface RequestParameter {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    route: string;
    body?: object;
    params?: { [key: string]: any };
    noException?: boolean;
}

async function makeRequest({ method, route, body, params, noException }: RequestParameter) {
    const url = new URL(API_URL+'/'+route, document.location.href);

    if (params) {
        // add query params
        for (const propName in params) {
            if (params[propName] === null || params[propName] === undefined) {
                delete params[propName];
            }
        }

        url.search = new URLSearchParams(params).toString();
    }

    const response = await fetch(url.toString(), {
        method,
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
    });

    // handle errors
    if(!noException && response.status >= 400) {
        throw response.status;
    }

    // return the result
    return JSON.parse((await response.text()) || "{}");
}

export async function getCashBookPdf(payload: CashBook) {
    return makeRequest({
        route: 'cashbook',
        method: 'POST',
        body: { startDate: payload.startDate, endDate: payload.endDate, startValue: payload.startValue, rows: payload.rows }
    });
}

type SymbolWithPrice = {
    symbol: string;
    price: number;
};

export async function getPrices(symbols: string[]): Promise<SymbolWithPrice[]> {
    return makeRequest({
        route: 'portfolio/prices',
        method: 'GET',
        params: {
            symbols: symbols.join(',')
        }
    });
}

type DateWithPrice = {
    date: string;
    price: number;
};

export type DateWithDividend = {
    date: string;
    price: number;
};

export type Logo = {
    url: string;
    containsBrandName: boolean;
};

type SymbolDetail = {
    priceHistory: DateWithPrice[];
    dividendHistory: DateWithDividend[];
    logo: Logo | null;
};

export async function getDetail(symbol: string): Promise<SymbolDetail> {
    return makeRequest({
        route: 'portfolio/detail',
        method: 'GET',
        params: {symbol}
    });
}

export type DownloadFormat = {
    code: string;
    extension: string;
    videoCodec: string | null;
    audioCodec: string | null;
    fileSize: number | null; // in bytes
    width: number | null;
    height: number | null;
};

export type VideoMetaData = {
    title: string;
    formats: DownloadFormat[];
};

export async function getVideoMetaData(url: string): Promise<VideoMetaData> {
    return makeRequest({
        route: 'youtube/metadata',
        method: 'GET',
        params: {url}
    });
}

export type Token = {
    token: string;
}

export async function generateToken(url: string, code: string | null, mp3: boolean | null, mp4: boolean | null): Promise<Token> {
    return makeRequest({
        route: 'youtube/generate-token',
        method: 'POST',
        params: {url, code, mp3, mp4}
    });
}

export async function downloadOnServer(token: string): Promise<Token> {
    return makeRequest({
        route: 'youtube/download',
        method: 'POST',
        params: {token}
    });
}

export type DownloadProgress = {
    progress: number;
}

export async function getDownloadProgress(token: string): Promise<DownloadProgress> {
    return makeRequest({
        route: 'youtube/progress',
        method: 'GET',
        params: {token}
    });
}