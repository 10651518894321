























































































import {Component, Vue} from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import {numberFormat} from "@/utils";
import PortfolioSubHeader from "@/components/portfolio/PortfolioSubHeader.vue";
import {Cashflow, ExpenseGroup, ExpenseItem, IncomeItem} from "@/model/cashflow";
import {ChartData, ChartOptions, ChartTooltipItem} from "chart.js";
import PieChart from "@/components/chart/PieChart.vue";

@Component({
  components: {PieChart, PortfolioSubHeader, PageHeader}
})
export default class CashflowView extends Vue {

  income: IncomeItem[] = [];
  expenses: ExpenseGroup[] = [];
  investments: ExpenseItem[] = [];

  pieItemsExpenseOnly: ExpenseItem[] = [];
  pieItemsAll: ExpenseItem[] = [];

  totalIncome = 0;
  totalExpenses = 0;
  totalInvestments = 0;
  free = 0;

  pieColors = ['#FF9800', '#9C27B0', '#009688', '#CDDC39', '#00BCD4'];
  baseChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'right',
      labels: {
        boxHeight: 50,
        filter: (legendItem: any) => {
          return legendItem.index < this.pieColors.length;
        }
      }
    }
  }

  get chartOptionsExpenseOnly() {
    return {
      ...this.baseChartOptions,
      tooltips: {
        callbacks: {
          label: (item: ChartTooltipItem) => {
            if (!item.index && item.index !== 0)
              return '';

            const expense = this.pieItemsExpenseOnly[item.index];
            return `${expense.expenseName}: ${this.currencyFormat(expense.amount)}`;
          },
        },
      },
    } as ChartOptions;
  }

  get chartOptionsAll() {
    return {
      ...this.baseChartOptions,
      tooltips: {
        callbacks: {
          label: (item: ChartTooltipItem) => {
            if (!item.index && item.index !== 0)
              return '';

            const expense = this.pieItemsAll[item.index];
            return `${expense.expenseName}: ${this.currencyFormat(expense.amount)}`;
          },
        },
      },
    } as ChartOptions;
  }

  chartDataExpenseOnly: ChartData | null = null;
  chartDataAll: ChartData | null = null;

  get currencyFormat() {
    return (number: number) => numberFormat(number, 2, '€');
  }

  get percentFormat() {
    return (number: number, decimals = 1) => numberFormat(number, decimals, '%');
  }

  get jsonData() {
    return {
      income: this.income,
      expenses: this.expenses,
      investments: this.investments
    } as Cashflow;
  }

  loadJson(data: Cashflow) {
    this.income = data.income;
    this.expenses = data.expenses;
    this.investments = data.investments;
    this.calculate();
  }

  calculate() {
    this.totalIncome = this.income.map(i => i.amount).reduce((prev, curr) => prev + curr);
    this.totalExpenses = this.expenses.map(e => e.items.map(i => i.amount)).flat().reduce((prev, curr) => prev + curr);
    this.totalInvestments = this.investments.map(i => i.amount).reduce((prev, curr) => prev + curr);
    this.free = this.totalIncome - this.totalExpenses; // excluding investments

    // pie charts
    this.pieItemsExpenseOnly = this.expenses.map(group => group.items).flat();
    this.pieItemsExpenseOnly.sort((a, b) => b.amount - a.amount);

    this.pieItemsAll = [
      ...this.expenses.map(group => group.items).flat(),
      ...this.investments,
    ];
    this.pieItemsAll.sort((a, b) => b.amount - a.amount);
    this.pieItemsAll.push({
      expenseName: 'Frei',
      amount: this.free - this.totalInvestments,
    });

    this.chartDataExpenseOnly = {
      labels: this.pieItemsExpenseOnly.map(i => i.expenseName),
      datasets: [{
        backgroundColor: this.pieColors,
        data: this.pieItemsExpenseOnly.map(i => i.amount),
      }]
    };

    this.chartDataAll = {
      labels: this.pieItemsAll.map(i => i.expenseName),
      datasets: [{
        backgroundColor: this.pieColors,
        data: this.pieItemsAll.map(i => i.amount),
      }]
    };
  }
}
