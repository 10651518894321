












































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import FormRow from "@/components/cashbook/FormRow.vue";
import {dateFormat} from "@/utils";
@Component({
  components: {FormRow}
})
export default class ExpenseForm extends Vue {

  @Prop({required: true})
  readonly date!: string;

  @Prop({required: false, default: false})
  readonly editMode!: boolean;

  @Prop({required: false})
  readonly prevDescription!: string | null;

  @Prop({required: false})
  readonly prevExpenseId!: number | null;

  @Prop({required: false})
  readonly prevExpense!: string | null;

  description = '';
  expenseId = '';
  expense = '';

  openDatePicker() {
    this.$emit('open-date-picker');
  }

  submit() {

    const expenseId = parseInt(this.expenseId);
    if(isNaN(expenseId)) {
      return;
    }

    const expenseValue = parseFloat(this.expense.replaceAll(',', '.'));
    if(isNaN(expenseValue)) {
      return;
    }

    this.expenseId = (expenseId + 1).toString();

    this.$emit('submit', {
      date: this.date,
      description: this.description,
      expenseId: expenseId,
      expense: expenseValue,
      type: 'EXPENSE'
    });
  }

  get dateFormat() {
    return (date: string) => dateFormat(date);
  }

  @Watch('prevDescription', { immediate: true })
  watchDescription(newVal: string) {
    if (newVal)
      this.description = newVal;
  }

  @Watch('prevExpenseId', { immediate: true })
  watchExpenseId(newVal: number) {
    if (newVal || newVal === 0)
      this.expenseId = `${newVal}`;
  }

  @Watch('prevExpense', { immediate: true })
  watchExpense(newVal: number) {
    if (newVal || newVal === 0)
      this.expense = `${newVal}`;
  }
}
