


























































































































































import {Component, Vue} from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import {BankAccount, BrokerAccount, CryptoWallet, LendingAccount, Portfolio, Stock} from "@/model/portfolio";
import AssetCard from "@/components/portfolio/AssetCard.vue";
import {numberFormat} from "@/utils";
import AssetRow from "@/components/portfolio/AssetRow.vue";
import {getPrices} from "@/api";
import AnimatedNumber from "@/components/portfolio/AnimatedNumber.vue";
import DetailPopup from "@/components/portfolio/DetailPopup.vue";
import NumberChangeIndicator from "@/components/portfolio/NumberChangeIndicator.vue";
import PortfolioSubHeader from "@/components/portfolio/PortfolioSubHeader.vue";

type PriceHistoryItem = {
  title: string;
  symbol: string;
}

@Component({
  components: {PortfolioSubHeader, NumberChangeIndicator, DetailPopup, AnimatedNumber, AssetRow, AssetCard, PageHeader}
})
export default class PortfolioView extends Vue {

  banks: BankAccount[] = [];
  brokers: BrokerAccount[] = [];
  lendingAccounts: LendingAccount[] = [];
  cryptoWallets: CryptoWallet[] = [];
  banksTotal = 0;
  stocksTotal = 0;
  stocksDividend = 0; // per year
  lendingTotal = 0;
  lendingYield = 0; // per year
  cryptosTotal = 0;
  total = 0;

  selectedItemForPriceHistory: PriceHistoryItem | null = null;

  timerId: number | null = null;

  get amountFormat() {
    return (number: number, decimals: number) => numberFormat(number, decimals);
  }

  get currencyFormat() {
    return (number: number) => numberFormat(number, 2, '€');
  }

  get percentFormat() {
    return (number: number, decimals = 1) => numberFormat(number, decimals, '%');
  }

  get percentage() {
    return (number: number) => this.percentFormat(number * 100 / this.total);
  }

  get percentageRaw() {
    return (number: number) => number / this.total;
  }

  get dividend() {
    return (stock: Stock) => {
      const dividend = stock.metadata.dividendPerMonth || stock.metadata.dividendPerQuarter || stock.metadata.dividendPerHalf || stock.metadata.dividendPerYear;
      if (!dividend)
        return null;

      const dividendString = this.currencyFormat(stock.amount * dividend);
      if (stock.metadata.dividendPerMonth) {
        return `${dividendString} / M`;
      } else if (stock.metadata.dividendPerQuarter) {
        return `${dividendString} / Q`;
      } else if (stock.metadata.dividendPerHalf) {
        return `${dividendString} / H`;
      } else {
        return `${dividendString} / J`;
      }
    }
  }

  get dividendY() {
    return (stock: Stock) => {
      const dividend = stock.metadata.dividendPerYear || stock.metadata.dividendPerHalf || stock.metadata.dividendPerQuarter || stock.metadata.dividendPerMonth;
      if (!dividend)
        return null;

      if (stock.metadata.dividendPerYear) {
        return `${this.currencyFormat(dividend * stock.amount)} / J`;
      } else if (stock.metadata.dividendPerHalf) {
        return `${this.currencyFormat(2 * dividend * stock.amount)} / J`;
      } else if (stock.metadata.dividendPerQuarter) {
        return `${this.currencyFormat(4 * dividend * stock.amount)} / J`;
      } else {
        return `${this.currencyFormat(12 * dividend * stock.amount)} / J`;
      }
    }
  }

  get dividendTooltip() {
    return (stock: Stock) => {
      const dividend = stock.metadata.dividendPerMonth || stock.metadata.dividendPerQuarter || stock.metadata.dividendPerHalf || stock.metadata.dividendPerYear;
      if (!dividend)
        return null;

      const dividendString = `${this.amountFormat(stock.amount, 4)} x ${this.currencyFormat(dividend)}`;

      if (stock.metadata.dividendPerMonth) {
        return `${dividendString} Dividende pro Monat`;
      } else if (stock.metadata.dividendPerQuarter) {
        return `${dividendString} Dividende pro Quartal`;
      } else if (stock.metadata.dividendPerHalf) {
        return `${dividendString} Dividende pro Halbjahr`;
      } else {
        return `${dividendString} Dividende pro Jahr`;
      }
    }
  }

  get dividendYTooltip() {
    return (stock: Stock) => {
      const dividend = stock.metadata.dividendPerYear || stock.metadata.dividendPerHalf || stock.metadata.dividendPerQuarter || stock.metadata.dividendPerMonth;
      if (!dividend)
        return null;

      const dividendString = `${this.amountFormat(stock.amount, 4)} x ${this.currencyFormat(dividend!)}`;
      if (stock.metadata.dividendPerYear) {
        return dividendString;
      } else if (stock.metadata.dividendPerHalf) {
        return `2 x ${dividendString}`;
      } else if (stock.metadata.dividendPerQuarter) {
        return `4 x ${dividendString}`;
      } else {
        return `12 x ${dividendString}`;
      }
    }
  }

  get brokerTotalValue() {
    return (broker: BrokerAccount) => {
      return broker.stocks
          .map((stock: Stock) => stock.amount * stock.metadata.price)
          .reduce((prev, curr) => prev + curr);
    }
  }

  get brokerTotalDividend() {
    return (broker: BrokerAccount) => {
      return broker.stocks.map(stock => {
        let dividend = 0;
        if (stock.metadata.dividendPerYear) {
          dividend = stock.metadata.dividendPerYear;
        } else if (stock.metadata.dividendPerHalf) {
          dividend = stock.metadata.dividendPerHalf * 2;
        } else if (stock.metadata.dividendPerQuarter) {
          dividend = stock.metadata.dividendPerQuarter * 4;
        } else if (stock.metadata.dividendPerMonth) {
          dividend = stock.metadata.dividendPerMonth * 12;
        }
        return dividend * stock.amount;
      }).reduce((prev, curr) => prev + curr);
    }
  }

  get jsonData() {
    return {
      banks: this.banks,
      brokers: this.brokers,
      lendingAccounts: this.lendingAccounts,
      cryptoWallets: this.cryptoWallets,
    } as Portfolio;
  }

  loadJson(data: Portfolio) {
    this.banks = data.banks;
    this.brokers = data.brokers;
    this.lendingAccounts = data.lendingAccounts;
    this.cryptoWallets = data.cryptoWallets;
    this.calculate();
  }

  showPriceHistory(title: string, symbol: string) {
    this.selectedItemForPriceHistory = {title, symbol};
  }

  calculate() {
    // cash
    this.banksTotal = this.banks
        .map(bank => bank.spaces.map(space => space.cash).reduce((prev, curr) => prev + curr))
        .reduce((prev, curr) => prev + curr);

    // stocks
    this.stocksTotal = this.brokers
        .map(broker => broker.stocks.map(stock => stock.amount * stock.metadata.price).reduce((prev, curr) => prev + curr))
        .reduce((prev, curr) => prev + curr);

    // lending
    this.lendingTotal = this.lendingAccounts
        .map(lending => lending.amount)
        .reduce((prev, curr) => prev + curr );

    // cryptos
    this.cryptosTotal = this.cryptoWallets
        .map(wallet => wallet.currencies.map(currency => currency.amount * currency.metadata.price).reduce((prev, curr) => prev + curr))
        .reduce((prev, curr) => prev + curr);

    this.total = this.banksTotal + this.stocksTotal + this.lendingTotal + this.cryptosTotal;

    // stocks dividend
    this.stocksDividend = this.brokers
      .map(broker => this.brokerTotalDividend(broker))
      .reduce((prev, curr) => prev + curr);

    // lending yield (absolute)
    this.lendingYield = this.lendingAccounts
      .map(lending => lending.amount * lending.yield)
      .reduce((prev, curr) => prev + curr );
  }

  async tick() {
    const symbols = [
      ...this.brokers.map(broker => broker.stocks.map(stock => stock.symbol)).flat(),
      ...this.cryptoWallets.map(wallet => wallet.currencies.map(currency => currency.symbol)).flat()
    ]
    const result = await getPrices(symbols); // fetch
    let changed = false;

    // update stocks
    for (const broker of this.brokers) {
      for (const stock of broker.stocks) {
        const price = result.find(r => r.symbol === stock.symbol);
        if (price) {
          stock.metadata.price = price.price;
          changed = true;
        }
      }
    }

    // update crypto
    for (const wallet of this.cryptoWallets) {
      for (const currency of wallet.currencies) {
        const price = result.find(r => r.symbol === currency.symbol);
        if (price) {
          currency.metadata.price = price.price;
          changed = true;
        }
      }
    }

    if (changed) {
      this.calculate();
    }
  }

  mounted() {
    this.timerId = setInterval(this.tick, 1500);
  }
}
